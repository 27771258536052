<template>
  <b-modal ref="sign-send" :cancel-disabled="data_submitted" :ok-disabled="!data_submitted" @cancel="onOK" @ok="onOK" ok-title="Done" id="sign-send" title="Sign and Send">
    <b-form>
      <div v-if="submit_title">
        <p><span><b>{{ submit_title }}</b></span></p>
      </div>
      <b-form-group
        label="Your Name for Signature (Required)"
        label-form="signer"
      >
        <b-form-input
          type="text"
          id="signer"
          v-model="signame"
          required
          placeholder=""
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label="Your Title (Optional)"
        label-form="sigtitle"
      >
        <b-form-input
          type="text"
          id="sigtitle"
          v-model="sigtitle"
          placeholder=""
        >
        </b-form-input>
      </b-form-group>

      <p>
        Signature: &nbsp;<span class="signature">{{ signame }}</span>
      </p>
      <!--
                    <b-button @click="generateKey" variant="primary"
                      >Create Signing Key</b-button
                    >
                      -->
      <b-button
        :disabled="!signame || submitting_data || data_submitted"
        variant="primary"
        @click="signDoc"
        ><b-icon icon="pen"></b-icon>
        <span v-if="!submitting_data">&nbsp; Sign and Send</span>
        <span v-if="submitting_data"
          >&nbsp;
          <b-spinner label="Sending"></b-spinner>
        </span>
      </b-button>
      <p v-if="sigkey" class="mt-3">
        <b-icon icon="key"></b-icon>
        &nbsp; Using Crypto Key
        <span>{{ sigkey.key.publicKey.algorithm.name }}</span>
        &nbsp;
        <span>{{ sigkey.key.publicKey.algorithm.namedCurve }}</span>
        created on {{ sigkey.dt_created }}
      </p>
    </b-form>
    <p v-if="data_signature_ts">
      Data was cryptographically signed on {{ data_signature_ts_local }}.
    </p>
    <b-alert :show="data_submitted" variant="success">
      <span v-if="confirmation_id">
        Confirmation ID: {{ confirmation_id }} <br />
      </span>
      Data submission successful. Thank you! You're all done unless you want to
      make changes and resubmit.
    </b-alert>
    <!--
    <b-button
      v-if="data_submitted"
      variant="primary"
      class="mt-3"
      @click="printDiv"
      >Print this Page</b-button
    >
    -->

    <!--
                  <b-button v-if="data_signature_ts && !data_submitted"
                    variant="primary" @click="resendData"
                    class="mb-3"
                  ><b-icon icon="arrow-repeat"></b-icon>&nbsp; Retry Sending Data
                  </b-button>
                  -->

    <b-alert
      :show="data_signature_ts && !data_submitted && !submitting_data"
      variant="danger"
    >
      Error submitting data. This is an unexpected error and will be looked
      into. You can try to submit the data again including any corrections.
    </b-alert>
  </b-modal>
</template>
<script>
import { set, get } from "idb-keyval"

// Base64 encode
function encode64(buff) {
  return btoa(
    new Uint8Array(buff).reduce((s, b) => s + String.fromCharCode(b), "")
  )
}

function textEncode(str) {
  if (window.TextEncoder) {
    return new TextEncoder("utf-8").encode(str)
  }
  var utf8 = unescape(encodeURIComponent(str))
  var result = new Uint8Array(utf8.length)
  for (var i = 0; i < utf8.length; i++) {
    result[i] = utf8.charCodeAt(i)
  }
  return result
}

export default {
  name: "SignAndSend",
  props: ['submit_data', 'submit_url', 'submit_title'],
  emits: ['signing-success'],
  data() {
    return {
      b64_public_key: null,
      signed_data: null,
      data_signature: null,
      data_signature_ts: null,
      submitting_data: false,
      data_submitted: false,
      confirmation_id: '',
      signame: '',
      sigtitle: '',
      sigkey: null,
    }
  },
  mounted() {
    console.log('SignAndSend component mounted')
    get("signing_key").then((key) => {
      if (key) {
        this.sigkey = key
        //console.log("name is " + key.name)
        console.log("key is " + key.key)
        console.log("pubkey is " + key.key.publicKey)
        console.log("privkey is " + key.key.privateKey)
        this.exportPublicKey(key.key)
      } else {
        this.generateKey()
      }
    })
    console.log('submit_data', this.submit_data)
  },
  computed: {
    data_signature_ts_local() {
      if (this.data_signature_ts) {
        return new Date(this.data_signature_ts).toLocaleString()
      }
      return ''
    }
  },
  methods: {
    onOK() {
      this.signed_data = null
      this.data_signature = null
      this.data_signature_ts = null
      this.submitting_data = false
      this.data_submitted = false
      this.signame = ''
      this.confirmation_id = ''
    },
    exportPublicKey(key) {
      // export the b64 public key
      window.crypto.subtle.exportKey("spki", key.publicKey).then((bkey) => {
        const b64key = encode64(bkey)
        const pem_key = `-----BEGIN PUBLIC KEY-----\n${b64key}\n-----END PUBLIC KEY-----`
        this.b64_public_key = pem_key
        console.log("public key b64: " + this.b64_public_key)
      })
    },
    generateKey() {
      window.crypto.subtle
        .generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256" //can be "P-256", "P-384", or "P-521"
          },
          false, //whether the key is extractable (i.e. can be used in exportKey)
          ["sign", "verify"] //can be any combination of "deriveKey" and "deriveBits"
        )
        .then((key) => {
          //returns a keypair object
          let current_datetime = new Date()
          let formatted_date =
            current_datetime.getFullYear() +
            "-" +
            (current_datetime.getMonth() + 1) +
            "-" +
            current_datetime.getDate() +
            " " +
            current_datetime.getHours() +
            ":" +
            current_datetime.getMinutes() +
            ":" +
            current_datetime.getSeconds()
          const wrapped_key = {
            //name: this.signame,
            dt_created: formatted_date,
            key: key
          }
          set("signing_key", wrapped_key)
            .then(() => console.log("signing key set"))
            .catch((err) => console.log("error occured", err))
          this.sigkey = wrapped_key
          console.log(key)
          console.log(key.publicKey)
          console.log(key.privateKey)
          this.exportPublicKey(key)
        })
        .catch(function(err) {
          console.error(err)
        })
    },
    signDoc() {
      console.log('submit_data', this.submit_data)
      console.log("formatting data")
      const data_signature_ts = new Date().toISOString()
      const submit_data = { ...this.submit_data }
      submit_data.signame = this.signame
      submit_data.sigtitle = this.sigtitle
      submit_data.data_signature_ts = data_signature_ts
      const serialized_data = JSON.stringify(submit_data)
      console.log(serialized_data)

      // byte array for signing
      // const encoded_message = new TextEncoder().encode(serialized_data)
      const encoded_message = textEncode(serialized_data)

      if (this.sigkey) {
        window.crypto.subtle
          // sign also hashes data - no need for seperate hash
          .sign(
            { name: "ECDSA", hash: { name: "SHA-256" } },
            this.sigkey.key.privateKey,
            encoded_message
          )
          .then((sig) => {
            console.log("verify")
            window.crypto.subtle
              .verify(
                { name: "ECDSA", hash: { name: "SHA-256" } },
                this.sigkey.key.publicKey,
                sig,
                encoded_message
              )
              .then((result) => {
                console.log("signature verified: " + result)
                console.log("sig bytes " + sig)
                console.log("sig b64 " + encode64(sig))
                this.signed_data = serialized_data
                this.data_signature = encode64(sig)
                this.data_signature_ts = data_signature_ts
                this.submitData(
                  this.submit_url,
                  this.signed_data,
                  this.data_signature,
                  this.b64_public_key
                )
              })
          })
      } else {
        // broken browser
        this.signed_data = serialized_data
        this.data_signature = ""
        this.b64_public_key = ""
        this.data_signature_ts = data_signature_ts
        this.submitData(
          this.submit_url,
          this.signed_data,
          this.data_signature,
          this.b64_public_key
        )
      }
    },
    resendData() {
      this.submitData(
        this.submit_url,
        this.signed_data,
        this.data_signature,
        this.b64_public_key
      )
    },
    submitData(url, signed_data, data_signature, b64_public_key) {
      // reset data submitted
      this.submitting_data = true
      this.data_submitted = false
      console.log("sending data")
      console.log(signed_data)
      console.log(data_signature)
      console.log(b64_public_key)
      const path = process.env.VUE_APP_API_URI + "/" + url
      this.axios
        .post(path, {
          signed_data: signed_data,
          data_signature: data_signature,
          public_key: b64_public_key
        })
        .then((res) => {
          this.submitting_data = false
          console.log(res)
          console.log("submit succeeded?")
          this.data_submitted = true
          if (res.data && res.data.confirmation_id) {
            this.confirmation_id = res.data.confirmation_id 
          }
          this.$emit('signing-success', res.data)
        })
        .catch((error) => {
          this.submitting_data = false
          console.error(error)
        })
    },
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Indie+Flower&display=swap");
.form-group.required > label:after {
  content: " *";
  color: red;
}

.signature {
  font-family: "Dancing Script", cursive;
  font-size: 2em;
}
</style>
