<template>
  <div>
      <b-form-group class="mt-4" label="Do you require assistance with your Lead Service Line Inventory?">
        <b-form-radio-group buttons @change="onAssistanceChanaged" v-model="need_assistance" :options="need_assistance_options" button-variant="outline-primary"></b-form-radio-group>
      </b-form-group>
      <b-alert :show="need_assistance_changed && !submit_error" variant="success">
        Thank you.  Your response was recorded.
        <span v-if="need_assistance == 'yes'">We will contact you shortly.</span>
      </b-alert>
      <b-alert :show="need_assistance_changed && submit_error" variant="danger">
        There was an error recording your response.
      </b-alert>
  </div>
</template>
<script>

export default {
  name: "LeadServiceLineInventoryAssistance",
  data() {
    return {
      submit_error: false,
      need_assistance: 'no response',
      need_assistance_changed: false,
      need_assistance_options: [
        { text: 'No Response', value: 'no response' },
        { text: 'No', value: 'no' },
        { text: 'Yes', value: 'yes' }
      ],
    }
  },
  methods: {
    async getAssistanceValue() {
      const url = process.env.VUE_APP_API_URI + '/pws-polls/lsli-assistance/' + this.pws_id_signed_in
      try {
        const res = await this.axios.get(url)
        console.log(res)
        if (res.data && res.data.poll_submit_data) {
          this.need_assistance = res.data.poll_submit_data.poll_data.need_assistance
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onAssistanceChanaged() {
      this.submit_error = false
      this.need_assistance_changed = true
      console.log(this.need_assistance)
      const url = process.env.VUE_APP_API_URI + '/pws-polls/lsli-assistance/' + this.pws_id_signed_in
      console.log('url', url)
      const data = { "poll_data": {need_assistance: this.need_assistance} }
      try {
        const res = await this.axios.post(url, data)
        console.log(res)
      } catch (error) {
        this.submit_error = true
        console.error(error)
      }
    },

  },
  mounted() {
    this.getAssistanceValue()
  },
  computed: {
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
  }
}
</script>
