<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Confirmation Notice - {{ formatDate(notice.dt_init) }}
        <span v-if="notice.data_signame !== null">
          <b-icon class="ml-5" icon="check-circle" variant="success"></b-icon>
          Complete
        </span>
        <span v-else>
          <b-icon class="ml-5" icon="x-circle" variant="warning"></b-icon>
          Incomplete
        </span>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <h5>Line Types</h5>
      <b-row>
        <b-col md="4"> <b>Lead:</b> {{ notice.lead_count }} </b-col>
        <b-col md="4"> <b>Galvanized:</b> {{ notice.galv_count }} </b-col>
        <b-col md="4"> <b>Unknown:</b> {{ notice.unkn_count }} </b-col>
      </b-row>
      <b-alert show variant="warning" class="mt-3">
        <b>Important:</b> Please verify the line type information.
        <b>Do not</b> confirm and sign this notice unless the numbers are
        correct. If the line type numbers are incorrect, please click on the
        following button and report the discrepancy below.
        <b-button size="sm" @click="show_user_comment = true"
          >Report Discrepancy</b-button
        >
      </b-alert>
      <div v-if="show_user_comment">
        Type your message below concerning the line type discrepancy and click
        the <em>Save Message</em> button. Someone with MSDH, Bureau of Public
        Water Supply will address the issue and contact you.
        <textarea
          class="form-control mt-3"
          rows="3"
          v-model="notice.user_comment"
        ></textarea>
        <button
          :disabled="!notice.user_comment"
          class="btn btn-primary mt-3"
          @click="onSaveUserMessage(notice)"
        >
          Save Message
        </button>
      </div>
      <hr />
      <h5 class="mt-3">Delivery Confirmation</h5>
      <b-row>
        <b-col>
          <b-checkbox v-model="use_custom_notice">
            Custom Notices Sent (Check if provided templates not used.)
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b>Date Delivered:</b> {{ notice.delivery_direct_date }}
        </b-col>
        <b-col md="4">
          <b>Date Mailed:</b> {{ notice.delivery_mail_date }}
        </b-col>
      </b-row>
      <b-row>
        <b-col> <b>Notes:</b> {{ notice.delivery_notes }} </b-col>
      </b-row>
      <b-row v-if="notice.data_signame !== null" class="mt-3">
        <b-col md="4">
          <b>Signed By:</b> {{ notice.data_signame }}
          <span v-if="notice.data_signame_title"
            >({{ notice.data_signame_title }})</span
          >
        </b-col>
        <b-col md="4">
          <b>Signed At:</b> {{ formatDateTime(notice.data_signature_ts) }}
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-button
          v-if="!use_custom_notice"
          class="mr-2"
          variant="primary"
          @click="editNotice"
          >Print Notices</b-button
        >
        <div class="mb-3" v-else>
          <b-spinner v-if="uploading_notice" label="Uploading..."></b-spinner>
          <ol>
            <li class="mt-1" v-for="filename in filenames" :key="filename">
              <b-button @click="showCustomNotice(filename)" variant="primary">
                <b-icon icon="file-earmark-text"></b-icon> View
              </b-button>
              <!-- delete button -->
              <b-button
                @click="onDeleteNoticeFile(filename)"
                variant="danger"
                class="ml-2"
              >
                <b-icon icon="trash"></b-icon> Delete
              </b-button>
            </li>
          </ol>
          Upload a Notice (PDF only):
          <b-form-file
            @input="onUploadNotice(notice.id)"
            v-model="upload_file"
            class="mr-2"
            variant="primary"
            accept="application/pdf"
            >Add Custom Notice</b-form-file
          >
        </div>
        <b-button
          :disabled="notice.data_signame !== null"
          class="mr-2"
          @click="editConfirmation"
          variant="primary"
          >Confirm and Sign</b-button
        >
        <b-button
          :disabled="notice.data_signame === null"
          @click="showNoticeConfirmation"
          variant="info"
        >
          <b-icon icon="file-earmark-arrow-down"></b-icon>
          Print Confirmation
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "LeadServiceLineNotice",
  props: {
    notice: Object,
  },
  data() {
    return {
      use_custom_notice: false,
      show_user_comment: false,
      upload_file: null,
      uploading_notice: false,
      filenames: [],
    };
  },
  methods: {
    formatDateTime(date) {
      if (!date) {
        return "";
      }
      return new Date(date).toLocaleString("sv");
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return new Date(date).toLocaleDateString("sv");
    },
    editNotice() {
      this.$emit("edit-notice", this.notice);
    },
    editConfirmation() {
      this.$emit("edit-confirmation", this.notice);
    },
    showNoticeConfirmation() {
      this.$emit("show-notice-confirmation", this.notice);
    },
    showCustomNotice(filename) {
      this.$emit("show-custom-notice", this.notice.id, filename);
    },
    async onGetNoticeFileNames(notice_id) {
      const url =
        process.env.VUE_APP_API_URI + "/lsli-notice/" + notice_id + "/files";
      try {
        const response = await this.axios.get(url);
        // set defaults for extra notice data
        console.log(response.data);
        this.filenames = response.data.filenames;
        if (response.data.filenames.length > 0) {
          this.use_custom_notice = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onUploadNotice() {
      if (!this.upload_file) {
        return;
      }
      try {
        this.uploading_notice = true;
        const url =
          process.env.VUE_APP_API_URI +
          "/lsli-notice/" +
          this.notice.id +
          "/files";
        const formData = new FormData();
        formData.append("notice_file", this.upload_file);
        const response = await this.axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response);
        this.upload_file = null;
        this.$emit("show-toast", {
          type: "success",
          message: "Notice uploaded",
        });
        this.onGetNoticeFileNames(this.notice.id);
      } catch (error) {
        console.error(error);
        this.$emit("show-toast", {
          type: "error",
          message: "Error uploading notice",
        });
      } finally {
        this.uploading_notice = false;
      }
    },
    async onDeleteNoticeFile(filename) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete this file?",
        {
          title: "Please Confirm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
        }
      );

      if (!confirmed) {
        return;
      }

      const url =
        process.env.VUE_APP_API_URI +
        "/lsli-notice/" +
        this.notice.id +
        "/files/" +
        filename;
      try {
        const response = await this.axios.delete(url);
        console.log(response);
        this.$emit("show-toast", {
          type: "success",
          message: "Notice deleted",
        });
        this.onGetNoticeFileNames(this.notice.id);
      } catch (error) {
        console.error(error);
        this.$emit("show-toast", {
          type: "error",
          message: "Error deleting notice",
        });
      }
    },
    async onSaveUserMessage() {
      console.log("save user message");
      const url =
        process.env.VUE_APP_API_URI +
        "/lsli-notice/" +
        this.notice.id +
        "/user-comment";
      const params = {
        user_comment: this.notice.user_comment,
      };
      try {
        const response = await this.axios.post(url, params);
        console.log(response);
        this.$emit("show-toast", {
          type: "success",
          message: "User comment saved",
        });
      } catch (error) {
        console.error(error);
        this.$emit("show-toast", {
          type: "error",
          message: "Error saving user comment",
        });
      }
    },
  },
  mounted() {
    console.log("mounted");
    console.log("notice", this.notice);
    this.onGetNoticeFileNames(this.notice.id);
  },
};
</script>
