<template>
  <div class="contianer">
    <h4>Lead Service Line Inventory</h4>
    <b-card no-body>
      <b-tabs card content-class="mt-3">
        <b-tab title="LSLI Consumer Notice" active>
          <LeadServiceLineNotices />
        </b-tab>
        <b-tab title="Inventory Upload">
          <LeadServiceLineInventoryUpload lazy />
        </b-tab>
        <b-tab title="Assistance">
          <LeadServiceLineInventoryAssistance lazy />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LeadServiceLineInventoryUpload from "/src/components/lsli/LeadServiceLineInventoryUpload.vue";
import LeadServiceLineInventoryAssistance from "/src/components/lsli/LeadServiceLineInventoryAssistance.vue";
import LeadServiceLineNotices from "../components/lsli/LeadServiceLineNotices.vue";

export default {
  name: "LeadInventory",
  data() {
    return {};
  },
  components: {
    LeadServiceLineInventoryUpload,
    LeadServiceLineInventoryAssistance,
    LeadServiceLineNotices,
  },
};
</script>
