<template>
  <div>
    <b-alert show variant="info" class="mt-3">
      Lead Service Line Inventory information and <b>templates</b> can be found on the <b-link href="https://msdh.ms.gov/page/30,25291,76,979.html" target="_blank">MSDH Website</b-link>.
    </b-alert>

    <b-card title="Upload Inventory File" class="mt-3">
    <div v-if="signed_in && pws_id_signed_in">
      <b-alert show variant="info">
        Uploading for <b>{{pws_label}}</b>.
        <b-link to="/change-water-system">Change PWS</b-link>
      </b-alert>
      <b-form-group label="Select a file (xlsx) to upload." label-for="lsli-upload">
        <b-form-file id="lsli-upload"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-model="lsli_upload_file"></b-form-file>
      </b-form-group>
      <b-button :disabled="!file_ok || uploading" @click="onUploadInventoryFile" variant="primary">{{ uploading ? 'Uploading...' : 'Upload'   }}</b-button>
      <div class="mt-3">
      <b-alert fade dismissible variant="success" v-model="upload_success_alert">
        File <u>{{lsli_upload_file_name}}</u> uploaded successfully. Thank you!
      </b-alert>
      <b-alert fade dismissible variant="danger" v-model="upload_failure_alert">
        File upload failed.
      </b-alert>
      </div>
    </div>
    <div v-else class="mt-5">
      <b-alert show variant="info">
        You must be <b-link to="signin">signed in </b-link>to <b>upload Lead Service Line Inventory files</b>.
      </b-alert>
    </div>
    </b-card>
    <b-card title="Uploaded Files" class="mt-3">
      <b-alert :show="upload_status == 'Approved'" variant="success">
        Confirmation:  You have uploaded a Lead Service Line Inventory spreadsheet.
        The content of the spreadsheet has not been verified for accuracy, but
        spreadsheets are being reviewed in the order they are received by the MSDH.  If
        additional information is needed, you will be contacted.
      </b-alert>
      <!--
      <b-alert :show="upload_status == 'Not Approved'" variant="info">
        You have not yet uploaded an approved file.
      </b-alert>
      -->
      <ul v-if="lsli_filenames.length > 0">
        <li v-for="file_info in lsli_filenames" :key="file_info.filename">
          <b-badge v-if="file_info.status == 'Approved'" variant="success">Confirmed</b-badge>
          <b-badge v-else-if="file_info.status == 'Rejected'" variant="danger">Rejected</b-badge>
          <b-badge v-else variant="info">Not Reviewed</b-badge>
          {{ file_info.filename }} - {{ file_info.upload_date }}
        </li>
      </ul>
      <b-alert v-else show variant="warning">
        No files uploaded.
      </b-alert>
    </b-card>
  </div>
</template>

<script>

export default {
  name: "LeadServiceLineInventoryUpload",
  data() {
    return {
      lsli_filenames: [],
      lsli_upload_file: null,
      lsli_upload_file_name: '',
      uploading: false,
      upload_success_alert: false,
      upload_failure_alert: false,
    }
  },
  methods: {
    async getFileList() {
      const url = process.env.VUE_APP_API_URI + '/lsli-files' + '?pws_id=' + this.pws_id_signed_in
      try {
        const res = await this.axios.get(url)
        this.lsli_filenames = []
        for (const file_data of res.data.files_uploaded) {
          //file_data.upload_date = new Date(file_data.upload_date).toLocaleString()
          this.lsli_filenames.push(file_data)
        }
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
    onUploadInventoryFile() {
      const form_data = new FormData()
      this.lsli_upload_file_name = this.lsli_upload_file.name
      form_data.append('lsli_file', this.lsli_upload_file)
      form_data.append('pws_id', this.pws_id_signed_in)
      this.uploading = true
      this.axios.post(process.env.VUE_APP_API_URI + '/lsli-file', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        this.uploading = false
        console.log(res)
        if (res.data && res.data.msg == 'OK') {
          this.upload_success_alert = true
          this.getFileList()
          this.lsli_upload_file = null
        } else {
          this.upload_failure_alert = true
        }
      }).catch((error) => {
        this.uploading = false
        console.error(error)
        this.upload_failure_alert = true
      })
    }
  },
  mounted() {
    this.getFileList()
  },
  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
    signed_in() {
      return this.$store.state.signed_in
    },
    file_ok() {
      return this.lsli_upload_file && this.lsli_upload_file.name.endsWith('.xlsx')
    },
    upload_status() {
      // if any status == 'Approved' return 'Approved' - otherwise return 'Not Approved'
      if (this.lsli_filenames.length > 0) {
        for (const file_info of this.lsli_filenames) {
          if (file_info.status == 'Approved') {
            return 'Approved'
          }
        }
      }
      return 'Not Approved'
    }
  }
}
</script>
