<template>
  <div>
    <p>Watch the
      <a target=_blank href="https://pws.mswater.us/static/lsli_notice.mp4">Lead Service Line Notification Demo</a> (Opens in New Window or Tab).
    </p>

    <div v-if="edit_notice">
      <b-form-group
        label-size="lg"
        label-class="font-weight-bold"
        label="Lead Service Line Inventory Notices"
        description="Data to populate for your notices."
      >
        <b-row>
          <b-col md="6">
            <b-form-group label="Contact Name">
              <b-form-input
                v-model="edit_notice.contact_name"
                label="Phone"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number">
              <b-form-input
                v-model="edit_notice.contact_phone"
                label="Phone"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Email (optional)">
              <b-form-input
                v-model="edit_notice.contact_email"
                label="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Web Address (Optional)">
              <b-form-input
                v-model="edit_notice.contact_website"
                label="Website Address"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button @click="doneEditNotice" variant="outline-secondary" class="mr-2">Close</b-button>
        <b-button :disabled="!edit_form_changed" @click="saveNotice('edit')" variant="primary">Save</b-button>
      </div>
      <p class="mt-3">
        Download Service Line Notices below. Notices are enabled only for
        service line types you reported.
      </p>
      <div class="mt-3">
        <b-button
          v-if="edit_notice.lead_count > 0"
          @click="showNotice('lead')"
          variant="info"
          class="mr-2"
          :disabled="edit_form_changed || !notice_info_complete"
        >
          <b-icon icon="file-earmark-arrow-down"></b-icon>
          Lead Service Line Notice
        </b-button>
        <b-button :disabled="edit_form_changed || !notice_info_complete" @click="showNotice('galv')" class="mr-2" variant="info" v-if="edit_notice.galv_count > 0">
          <b-icon icon="file-earmark-arrow-down"></b-icon>
          Galvanized Service Line Notice
        </b-button>
        <b-button :disabled="edit_form_changed || !notice_info_complete" @click="showNotice('unkn')" variant="info" v-if="edit_notice.unkn_count > 0">
          <b-icon icon="file-earmark-arrow-down"></b-icon>
          Unknown Service Line Notice
        </b-button>
      </div>
    </div>

    <div v-if="confirm_notice">
      <b-form-group
        label-size="lg"
        label-class="font-weight-bold"
        label="Lead Service Line Inventory Notice Confirmation"
      >
        <b-row>
          <b-col md="6">
            <b-form-group label="Date Delivered">
              <b-form-input
                type="date"
                v-model="confirm_notice.delivery_direct_date"
                label="Date Delivered"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date Mailed">
              <b-form-input
                type="date"
                v-model="confirm_notice.delivery_mail_date"
                label="Date Mailed"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Delivery Notes">
              <b-form-input
                v-model="confirm_notice.delivery_notes"
                label="Notes"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
            <b-button @click="onCancelConfirmation" variant="outline-secondary" class="mr-2">
              Close
            </b-button>
            <b-button @click="saveNotice('confirmation')" variant="primary" :disabled="!confirm_ok || !confirm_form_changed">
              Save
            </b-button>
        </div>
      </b-form-group>
      <p><strong>Once you have completed and saved a notice date, you may confirm and sign.</strong></p>
      <p>
        The public water system {{ pws_label }} herby affirms that LSLI customer notification has been provided to consumers in accordance with the delivery, content, and format requirements and deadlines in the Safe Drinking Water Act 40 CFR 141.85(e) given by method(s) indicated above.</p>
      <p>
All customers of the above-named public water system with unknown, galvanized requiring replacement, and/or lead service lines have been notified in accordance with the Code of Federal Regulations 40 CFR Part 141 Subpart-I
</p>
      <b-checkbox v-model="affirm_notice" class="mt-w">
        I affirm that the information provided is accurate and complete.
      </b-checkbox>
      <b-button class="mt-2" @click="onShowSignSend" variant="primary" :disabled="!sign_ok">
        Begin Sign and Send
      </b-button>
    </div>

    <div v-if="lsli_notices_loaded && lsli_notices.length === 0">
      <b-alert show variant="info">
        <h4 class="alert-heading">No Lead Service Line Inventory Notices</h4>
        <p>
          You have no Lead Service Line Inventory Notices.
        </p>
      </b-alert>
    </div>

    <div v-if="!edit_notice && !confirm_notice">
      <lead-service-line-notice v-for="notice in lsli_notices" :notice="notice" v-bind:key="notice.id" @show-toast="showToast" @edit-notice="editNotice" @edit-confirmation="editConfirmation" @show-notice-confirmation="showNoticeConfirmation" @show-custom-notice="showCustomNotice" />
    </div>

    <PdfModal
      modal_id="lsli_notice_modal"
      :pdf_url="notice_url"
      :pdf_title="notice_title"
    />
    <SignAndSend
      :submit_title="`Lead Service Line Inventory for ${pws_label}`"
      :submit_url="sign_url"
      :submit_data="confirm_notice"
      @signing-success="onSigningSuccess"
    />

  </div>
</template>

<script>
import PdfModal from "@/components/PdfModal.vue";
import SignAndSend from "@/components/SignAndSend.vue";
import LeadServiceLineNotice   from "./LeadServiceLineNotice.vue";

export default {
  name: "LeadServiceLineNotices",
  components: {
    PdfModal,
    SignAndSend,
    LeadServiceLineNotice,
  },
  data() {
    return {
      lsli_notices: [],
      lsli_notices_loaded: false,
      notice_url: "",
      notice_title: "Set Title",
      edit_notice: null,
      edit_notice_orig: null,
      show_edit_modal: false,
      affirm_notice: null,
      confirm_notice: null,
      confirm_notice_orig: null,
      confirmation_notice: null,
    };
  },
  methods: {
    formatDateTime(date) {
      if (!date) {
        return "";
      }
      return new Date(date).toLocaleString("sv");
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return new Date(date).toLocaleDateString("sv");
    },
    async getNotices() {
      const url =
        process.env.VUE_APP_API_URI +
        "/lsli-notices" +
        "?pws_id=" +
        this.pws_id_signed_in;
      try {
        const res = await this.axios.get(url);
        this.lsli_notices = [];
        for (const notice of res.data) {
          console.log(notice);
          this.lsli_notices.push(notice);
        }
        this.lsli_notices_loaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    showToast({ type, message }) {
      console.log("show toast", type, message);
      const config = {
        success: {
          title: 'Success',
          msg_default: 'Data saved successfully!'
        },
        error: {
          title: 'Error',
          msg_default: 'An error occurred.'
        }
      }
      const { title, msg_default } = config[type]
      this.$bvToast.toast(message || msg_default , {
        title: title,
        variant: type,
        autoHideDelay: 5000
      })
    },
    editNotice(notice) {
      console.log("edit notice", notice);
      this.edit_notice = { ...notice };
      this.edit_notice_orig = { ...notice };
      //this.show_edit_modal = true;
    },
    editConfirmation(notice) {
      console.log("edit confirmation", notice);
      this.confirm_notice = { ...notice };
      this.confirm_notice_orig = { ...notice };
    },
    onCancelConfirmation() {
      this.confirm_notice = null;
      this.confirm_notice_orig = null;
    },
    doneEditNotice() {
      console.log("done editing notice", this.edit_notice);
      this.edit_notice = null;
      this.edit_notice_orig = null;
    },
    async saveNotice(save_type) {
      let notice
      if (save_type == "edit") {
        notice = this.edit_notice;
      } else {
        notice = this.confirm_notice;
      }
      console.log("save notice", this.edit_notice);
      const url = process.env.VUE_APP_API_URI + "/lsli-notice"
      const params = {
        notice_id: notice.id,
        contact_name: notice.contact_name,
        phone: notice.contact_phone,
        email: notice.contact_email,
        website: notice.contact_website,
        delivery_mail_date: notice.delivery_mail_date,
        delivery_direct_date: notice.delivery_direct_date,
        delivery_notes: notice.delivery_notes,
      }
      try {
        const response = await this.axios.post(url, params);
        console.log(response);
        // update the original
        if (save_type == "edit") {
          this.edit_notice_orig = { ...this.edit_notice };
        } else {
          this.confirm_notice_orig = { ...this.confirm_notice };
        }
        // update lsli_notices
        const index = this.lsli_notices.findIndex(
          (n) => n.id === notice.id
        );
        this.lsli_notices[index] = { ...notice };
        this.showToast({ type: "success" });
      } catch (error) {
        console.error(error);
        this.showToast({ type: "error" });
      }
    },
    printNotice(notice) {
      console.log("print notice", notice);
    },
    printConfirmation(notice) {
      console.log("print notice confirmation", notice);
    },
    showNotice(notice_type) {
      console.log("show lead notice");
      const titles = {
        lead: "Lead Service Line Notice",
        galv: "Galvanized Service Line Notice",
        unkn: "Unknown Service Line Notice",
      };
      this.notice_title = titles[notice_type];
      this.notice_url =
        process.env.VUE_APP_API_URI +
        "/lsli-notice" +
        "?notice_id=" +
        this.edit_notice.id +
        "&notice_type=" + notice_type;
      this.$bvModal.show("lsli_notice_modal");
    },
    showCustomNotice(notice_id, filename) {
      console.log("show custom notice", notice_id);
      this.notice_title = "Custom Notice";
      this.notice_url =
        process.env.VUE_APP_API_URI +
        "/lsli-notice/" + notice_id + "/files/" + filename;
      console.log(this.notice_url)
      this.$bvModal.show("lsli_notice_modal");
    },
    showNoticeConfirmation(notice) {
      console.log("show lead notice confirmation");
      this.notice_title = "Lead Service Line Notice Confirmation"
      this.notice_url =
        process.env.VUE_APP_API_URI +
        "/lsli-notice-confirmation?notice_id=" + notice.id
      this.$bvModal.show("lsli_notice_modal");
    },
    onShowSignSend() {
      this.$bvModal.show('sign-send')
    },
    async onSigningSuccess() {
      console.log("signing success");
      const notice_id = this.confirm_notice.id;
      this.confirm_notice = null;
      this.confirm_notice_orig = null;
      this.getNotices();
      this.confirmation_notice = this.lsli_notices.find(
        (n) => n.id === notice_id
      );
    }
  },
  mounted() {
    this.getNotices();
  },
  computed: {
    edit_form_changed() {
      return JSON.stringify(this.edit_notice) !== JSON.stringify(this.edit_notice_orig);
    },
    confirm_form_changed() {
      return JSON.stringify(this.confirm_notice) !== JSON.stringify(this.confirm_notice_orig);
    },
    notice_info_complete() {
      return this.edit_notice.contact_name && this.edit_notice.contact_phone;
    },
    confirm_ok() {
      return this.confirm_notice.delivery_direct_date || this.confirm_notice.delivery_mail_date;
    },
    sign_ok() {
      return this.confirm_ok && !this.confirm_form_changed && this.affirm_notice;
    },
    sign_url() {
      if (!this.confirm_notice) {
        return "";
      }
      return "lsli-notice/" + this.confirm_notice.id + "/sign";
    },
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
    signed_in() {
      return this.$store.state.signed_in;
    },
  },
};
</script>
